// frontend/src/pages/Profile.js
import React, { useState, useEffect } from 'react';
// Удаляем импорт useNavigate
import { useAuth } from '../contexts/AuthContext';
import LocationCard from '../components/LocationCard';
import './Profile.css';

const ProfileTabs = {
  FAVORITES: 'favorites',
  GENERATED: 'generated',
  CREDITS: 'credits'
};

const Profile = () => {
  const { user, login } = useAuth();
  const [activeTab, setActiveTab] = useState(ProfileTabs.FAVORITES);
  const [favorites, setFavorites] = useState([]);
  const [generatedLocations, setGeneratedLocations] = useState([]);
  const [credits, setCredits] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!user) {
      setLoading(false);
      return;
    }

    const fetchProfileData = async () => {
      try {
        const [favoritesRes, generatedRes, creditsRes] = await Promise.all([
          fetch('/api/favorites', { credentials: 'include' }),
          fetch('/api/generation/my-locations', { credentials: 'include' }),
          fetch('/api/payments/credits', { credentials: 'include' })
        ]);

        const [favoritesData, generatedData, creditsData] = await Promise.all([
          favoritesRes.json(),
          generatedRes.json(),
          creditsRes.json()
        ]);

        setFavorites(favoritesData);
        setGeneratedLocations(generatedData);
        setCredits(creditsData.credits);
      } catch (error) {
        console.error('Error fetching profile data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProfileData();
  }, [user]);

  if (loading) {
    return <div className="profile-loading">Loading...</div>;
  }

  if (!user) {
    return (
      <div className="profile-login">
        <h2>Please sign in to view your profile</h2>
        <button onClick={login} className="login-button">
          <img 
            src="/google-icon.png" 
            alt="Google" 
            className="google-icon"
          />
          Sign in with Google
        </button>
      </div>
    );
  }

  return (
    <div className="profile-container">
      <div className="profile-header">
        <div className="profile-info">
          <img src={user.picture} alt={user.name} className="profile-avatar" />
          <div>
            <h2>{user.name}</h2>
            <p>{user.email}</p>
          </div>
        </div>
        <div className="credits-info">
          <span className="credits-amount">{credits}</span>
          <span className="credits-label">Credits</span>
          <button className="buy-credits-btn">Buy Credits</button>
        </div>
      </div>

      <div className="profile-tabs">
        <button 
          className={`tab ${activeTab === ProfileTabs.FAVORITES ? 'active' : ''}`}
          onClick={() => setActiveTab(ProfileTabs.FAVORITES)}
        >
          Favorites
        </button>
        <button 
          className={`tab ${activeTab === ProfileTabs.GENERATED ? 'active' : ''}`}
          onClick={() => setActiveTab(ProfileTabs.GENERATED)}
        >
          Generated Locations
        </button>
      </div>

      <div className="tab-content">
        {activeTab === ProfileTabs.FAVORITES && (
          <div className="locations-grid">
            {favorites.length > 0 ? (
              favorites.map(location => (
                <LocationCard 
                  key={location._id} 
                  location={location}
                  isFavorite={true}
                  onFavoriteToggle={() => {/* Add logic */}}
                />
              ))
            ) : (
              <p>No favorite locations yet</p>
            )}
          </div>
        )}

        {activeTab === ProfileTabs.GENERATED && (
          <div className="locations-grid">
            {generatedLocations.length > 0 ? (
              generatedLocations.map(location => (
                <LocationCard 
                  key={location._id} 
                  location={location}
                  isGenerated={true}
                />
              ))
            ) : (
              <p>No generated locations yet</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Profile;