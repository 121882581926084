
// File: frontend/src/pages/MapView.js
import React, { useState, useEffect, useCallback, useRef } from "react";
import { MapContainer, TileLayer, Marker, Popup, Polyline, useMap } from "react-leaflet";
import { useSearchParams } from 'react-router-dom';
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "./MapView.css";
import { locationsByCity, getNearestCity } from '../data/locations';

delete L.Icon.Default.prototype._getIconUrl;

const createNumberedIcon = (number) => {
 return L.divIcon({
   className: "custom-marker",
   html: `<div style="
     background-color: #e74c3c;
     width: 30px;
     height: 30px;
     display: flex;
     align-items: center;
     justify-content: center;
     border-radius: 50%;
     color: white;
     font-weight: bold;
     border: 2px solid white;
     box-shadow: 0 2px 5px rgba(0,0,0,0.3);
   ">${number}</div>`,
   iconSize: [30, 30],
   iconAnchor: [15, 15],
   popupAnchor: [0, -15]
 });
};

const createHeadingIcon = (heading) => {
 return L.divIcon({
   className: "user-heading-marker",
   html: `<div style="
     transform: rotate(${heading}deg);
     width: 40px;
     height: 40px;
     background-color: #2563eb;
     border-radius: 50% 50% 50% 0;
     border: 2px solid white;
     box-shadow: 0 2px 5px rgba(0,0,0,0.3);
   "></div>`,
   iconSize: [40, 40],
   iconAnchor: [20, 20],
   popupAnchor: [0, -20]
 });
};

const calculateDistance = (coord1, coord2) => {
 const R = 6371;
 const dLat = (coord2[0] - coord1[0]) * Math.PI / 180;
 const dLon = (coord2[1] - coord1[1]) * Math.PI / 180;
 const a = 
   Math.sin(dLat/2) * Math.sin(dLat/2) +
   Math.cos(coord1[0] * Math.PI / 180) * Math.cos(coord2[0] * Math.PI / 180) * 
   Math.sin(dLon/2) * Math.sin(dLon/2);
 const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
 return R * c;
};

const sortByNearest = (places, startLocation) => {
 const result = [];
 const remaining = [...places];
 let currentLocation = startLocation;

 while (remaining.length > 0) {
   let nearestIndex = 0;
   let shortestDistance = calculateDistance(currentLocation, remaining[0].coordinates);

   for (let i = 1; i < remaining.length; i++) {
     const distance = calculateDistance(currentLocation, remaining[i].coordinates);
     if (distance < shortestDistance) {
       shortestDistance = distance;
       nearestIndex = i;
     }
   }

   const nearestPlace = remaining[nearestIndex];
   result.push(nearestPlace);
   currentLocation = nearestPlace.coordinates;
   remaining.splice(nearestIndex, 1);
 }

 return result;
};

const getWalkingRoute = async (start, end) => {
 try {
   const response = await fetch(
     `https://router.project-osrm.org/route/v1/foot/${start[1]},${start[0]};${end[1]},${end[0]}?overview=full&geometries=geojson`
   );
   const data = await response.json();
   return data.code === "Ok" ? data.routes[0].geometry.coordinates.map(coord => [coord[1], coord[0]]) : null;
 } catch (error) {
   console.error("Error fetching route:", error);
   return null;
 }
};

const calculateWalkingTime = (start, end) => {
 const R = 6371;
 const dLat = (end[0] - start[0]) * Math.PI / 180;
 const dLon = (end[1] - start[1]) * Math.PI / 180;
 const a = 
   Math.sin(dLat/2) * Math.sin(dLat/2) +
   Math.cos(start[0] * Math.PI / 180) * Math.cos(end[0] * Math.PI / 180) * 
   Math.sin(dLon/2) * Math.sin(dLon/2);
 const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
 const distance = R * c;
 return Math.round((distance / 5) * 60);
};

const MapView = () => {
 const [searchParams] = useSearchParams();
 const highlightedPlaceId = searchParams.get('highlight');

 // States
 const [currentCity, setCurrentCity] = useState(null);
 const [sortedSpots, setSortedSpots] = useState([]);
 const [isRouteExpanded, setIsRouteExpanded] = useState(true);
 const [userLocation, setUserLocation] = useState(null);
 const [center] = useState([35.6762, 139.6503]);
 const [loading, setLoading] = useState(true);
 const [routes, setRoutes] = useState([]);
 const [currentAudioElement, setCurrentAudioElement] = useState(null);
 const [userHeading, setUserHeading] = useState(0);
 const [watchId, setWatchId] = useState(null);

 // Refs
 const mapRef = useRef(null);
 const initRef = useRef(false);

 const MapController = () => {
  const map = useMap();
  
  useEffect(() => {
    if (map && !mapRef.current) {
      mapRef.current = map;
      handleMapReady(map);
    }
  }, [map]);

  return null;
};

const handleMapReady = useCallback((map) => {
  if (!initRef.current) {
    initRef.current = true;
    
    if (highlightedPlaceId) {
      Object.values(locationsByCity).forEach(citySpots => {
        const place = citySpots.find(spot => spot.id === highlightedPlaceId);
        if (place) {
          map.setView(place.coordinates, 15);
          const nearestCity = getNearestCity(place.coordinates);
          setCurrentCity(nearestCity);
          const citySpots = locationsByCity[nearestCity] || [];
          const sorted = sortByNearest(citySpots, place.coordinates);
          setSortedSpots(sorted);
          setUserLocation(place.coordinates);
        }
      });
    } else if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const userPos = [latitude, longitude];
          map.setView(userPos, 13);
          
          const nearestCity = getNearestCity(userPos);
          setCurrentCity(nearestCity);
          const citySpots = locationsByCity[nearestCity] || [];
          const sorted = sortByNearest(citySpots, userPos);
          setSortedSpots(sorted);
          setUserLocation(userPos);
          
          const fetchRoutes = async () => {
            const walkingRoutes = [];
            let currentPos = userPos;
            for (const spot of sorted) {
              const routePoints = await getWalkingRoute(currentPos, spot.coordinates);
              if (routePoints) {
                walkingRoutes.push(routePoints);
              }
              currentPos = spot.coordinates;
            }
            setRoutes(walkingRoutes);
            setLoading(false);
          };
          
          fetchRoutes();
        },
        (error) => {
          console.error("Error getting location:", error);
          setLoading(false);
        }
      );
    }
  }
}, [highlightedPlaceId]);

 const startWatchingPosition = useCallback(() => {
   if (!watchId && navigator.geolocation) {
     const id = navigator.geolocation.watchPosition(
       (position) => {
         const { latitude, longitude } = position.coords;
         setUserLocation([latitude, longitude]);
       },
       (error) => console.error("Error:", error),
       {
         enableHighAccuracy: true,
         maximumAge: 0,
         timeout: 5000
       }
     );
     setWatchId(id);
   }
 }, [watchId]);

 const refreshLocation = useCallback(() => {
   if (navigator.geolocation) {
     if (watchId) {
       navigator.geolocation.clearWatch(watchId);
     }
     
     navigator.geolocation.getCurrentPosition(
       (position) => {
         const { latitude, longitude } = position.coords;
         const userPos = [latitude, longitude];
         setUserLocation(userPos);
         
         if (mapRef.current) {
           mapRef.current.setView(userPos, 13, {
             animate: true
           });
         }

         const nearestCity = getNearestCity(userPos);
         setCurrentCity(nearestCity);
         const citySpots = locationsByCity[nearestCity] || [];
         const sorted = sortByNearest(citySpots, userPos);
         setSortedSpots(sorted);
       },
       (error) => console.error("Error:", error)
     );
   }
 }, [watchId]);

 useEffect(() => {
   const handleOrientation = (event) => {
     if (event.webkitCompassHeading) {
       setUserHeading(event.webkitCompassHeading);
     } else if (event.alpha) {
       setUserHeading(360 - event.alpha);
     }
   };

   let orientationCleanup = false;

   const setupOrientation = async () => {
     if (window.DeviceOrientationEvent) {
       if (typeof DeviceOrientationEvent.requestPermission === 'function') {
         try {
           const permission = await DeviceOrientationEvent.requestPermission();
           if (permission === 'granted' && !orientationCleanup) {
             window.addEventListener('deviceorientation', handleOrientation);
           }
         } catch (err) {
           console.error("Error requesting orientation permission:", err);
         }
       } else if (!orientationCleanup) {
         window.addEventListener('deviceorientation', handleOrientation);
       }
     }
   };

   setupOrientation();
   
   if (!highlightedPlaceId) {
     startWatchingPosition();
   }

   return () => {
     orientationCleanup = true;
     window.removeEventListener('deviceorientation', handleOrientation);
     if (watchId) {
       navigator.geolocation.clearWatch(watchId);
     }
   };
 }, [startWatchingPosition, watchId, highlightedPlaceId]);

 const handleAudioPlay = useCallback((e, spot) => {
   const audioElement = e.target;
   
   if (currentAudioElement && currentAudioElement !== audioElement) {
     currentAudioElement.pause();
     currentAudioElement.currentTime = 0;
   }
   
   setCurrentAudioElement(audioElement);
 }, [currentAudioElement]);

 useEffect(() => {
   return () => {
     if (currentAudioElement) {
       currentAudioElement.pause();
       currentAudioElement.currentTime = 0;
     }
   };
 }, [currentAudioElement]);

 return (
   <div style={{ height: "100vh", width: "100%" }}>
     {loading && (
       <div style={{
         position: "absolute",
         top: "50%",
         left: "50%",
         transform: "translate(-50%, -50%)",
         zIndex: 1000,
         background: "white",
         padding: "20px",
         borderRadius: "8px",
         boxShadow: "0 2px 6px rgba(0,0,0,0.1)"
       }}>
         Loading map...
       </div>
     )}

<MapContainer 
  center={center}
  zoom={13} 
  style={{ height: "100%", width: "100%" }}
>
  <MapController />
       <TileLayer
         url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png"
         attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
       />

       {routes.map((route, index) => (
         <Polyline 
           key={index}
           positions={route}
           color="#2563eb"
           weight={5}
           opacity={0.8}
           dashArray="10, 10"
         />
       ))}

       <div className="leaflet-top leaflet-left" style={{ top: '85px' }}>
         <div className="leaflet-control leaflet-bar">
           <button
             onClick={refreshLocation}
             className="leaflet-control-locate"
             style={{
               width: "30px",
               height: "30px",
               backgroundColor: "white",
               border: "2px solid rgba(0,0,0,0.2)",
               borderRadius: "4px",
               cursor: "pointer",
               display: "flex",
               alignItems: "center",
               justifyContent: "center",
               padding: 0
             }}
             aria-label="Center on location"
           >
             <svg
               xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 24 24"
               fill="none"
               stroke="currentColor"
               strokeWidth="2"
               strokeLinecap="round"
               strokeLinejoin="round"
               style={{ width: "16px", height: "16px" }}
             >
               <path d="M2 12h3m14 0h3M12 2v3m0 14v3"/>
               <circle cx="12" cy="12" r="7"/>
               <path d="M12 7l5 5-5 5V7z"/>
             </svg>
           </button>
         </div>
       </div>

       {userLocation && (
         <Marker position={userLocation} icon={createHeadingIcon(userHeading)}>
           <Popup>
             <div>
               <h3>You are here</h3>
               <p>Heading: {Math.round(userHeading)}°</p>
               {currentCity && <p>Nearest City: {currentCity}</p>}
             </div>
           </Popup>
         </Marker>
       )}

{sortedSpots.map((spot, index) => (
  <Marker 
    key={spot.id} 
    position={spot.coordinates} 
    icon={createNumberedIcon(index + 1)}
  >
    <Popup maxWidth={350}>
      <div className="custom-popup">
        <div className="popup-header">
          <span className="popup-number">{index + 1}</span>
          <h3>{spot.name}</h3>
        </div>
        
        {spot.image && (
          <div className="popup-image-container">
            <img 
              src={spot.image} 
              alt={spot.name}
              className="popup-image"
            />
          </div>
        )}
        
        <div className="popup-content">
          <p className="popup-description">{spot.description}</p>
          
          <div className="popup-details">
            <div className="detail-item">
              <i className="far fa-clock"></i>
              <span><strong>Duration:</strong> {spot.estimatedTime}</span>
            </div>
            
            {index < sortedSpots.length - 1 && (
              <div className="detail-item">
                <i className="fas fa-walking"></i>
                <span>
                  <strong>To next:</strong> {" "}
                  {calculateWalkingTime(
                    spot.coordinates,
                    sortedSpots[index + 1].coordinates
                  )} min walk
                </span>
              </div>
            )}
          </div>

          <div className="popup-audio">
            <audio
              controls
              src={spot.audioGuide}
              className="audio-player"
              onPlay={(e) => handleAudioPlay(e, spot)}
            >
              Your browser does not support the audio element.
            </audio>
            
            <div className="transcript-container">
              <div className="transcript-header">
                <i className="fas fa-file-alt"></i>
                <strong>Audio Guide Transcript</strong>
              </div>
              <p className="transcript-text">{spot.audioDescription}</p>
            </div>
          </div>
        </div>
      </div>
    </Popup>
  </Marker>
       ))}
     </MapContainer>

     <div style={{
       position: "absolute",
       bottom: "20px",
       left: "20px",
       right: "20px",
       background: "white",
       padding: "15px",
       borderRadius: "8px",
       boxShadow: "0 2px 6px rgba(0,0,0,0.1)",
       zIndex: 1000,
       transition: "transform 0.3s ease",
       transform: isRouteExpanded ? "translateY(0)" : "translateY(calc(100% - 50px))"
     }}>
       <div 
         style={{ 
           display: "flex", 
           justifyContent: "space-between", 
           alignItems: "center",
           cursor: "pointer"
         }}
         onClick={() => setIsRouteExpanded(!isRouteExpanded)}
       >
         <h3 style={{ marginBottom: isRouteExpanded ? "10px" : "0", fontWeight: "bold" }}>
           {currentCity ? `${currentCity} Route Guide` : 'Suggested Route'}
         </h3>
         <span style={{ 
           transform: isRouteExpanded ? "rotate(180deg)" : "rotate(0deg)",
           transition: "transform 0.3s ease"
         }}>
           ▼
         </span>
       </div>
       <div style={{ 
         display: "flex", 
         flexWrap: "wrap", 
         gap: "10px",
         maxHeight: isRouteExpanded ? "500px" : "0",
         overflow: "hidden",
         transition: "max-height 0.3s ease"
       }}>
         {sortedSpots.map((spot, index) => (
           <div key={spot.id} style={{ display: "flex", alignItems: "center" }}>
             <span style={{
               background: "#e74c3c",
               color: "white",
               width: "24px",
               height: "24px",
               borderRadius: "50%",
               display: "flex",
               alignItems: "center",
               justifyContent: "center",
               marginRight: "5px",
               fontWeight: "bold"
             }}>{index + 1}</span>
             <span>{spot.name}</span>
             {index < sortedSpots.length - 1 && (
               <span style={{ margin: "0 10px", color: "#666" }}>
                 ({calculateWalkingTime(
                   spot.coordinates,
                   sortedSpots[index + 1].coordinates
                 )} min) →
               </span>
             )}
           </div>
         ))}
       </div>
     </div>
   </div>
 );
};

export default MapView;