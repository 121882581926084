// File: frontend/src/components/SearchBox.js
import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { locationsByCity } from '../data/locations';
import './SearchBox.css';

const SearchBox = () => {
  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const navigate = useNavigate();

  const handleSearch = useCallback((value) => {
    setSearchValue(value);
    if (value.length < 2) {
      setSearchResults([]);
      return;
    }

    const results = [];
    Object.entries(locationsByCity).forEach(([city, places]) => {
      places.forEach(place => {
        if (place.name.toLowerCase().includes(value.toLowerCase()) ||
            place.description.toLowerCase().includes(value.toLowerCase())) {
          results.push({ ...place, city });
        }
      });
    });
    setSearchResults(results.slice(0, 5));
  }, []);

// В SearchBox.js изменим handleSelectPlace:
const handleSelectPlace = (place) => {
  setSearchValue('');
  setSearchResults([]);
  navigate(`/map?highlight=${place.id}`);
};

  return (
    <div className="search-box-container">
      <input
        type="text"
        value={searchValue}
        onChange={(e) => handleSearch(e.target.value)}
        placeholder="Search places..."
        className="search-input"
      />
      {searchResults.length > 0 && (
        <div className="search-results">
          {searchResults.map((place) => (
            <div
              key={place.id}
              onClick={() => handleSelectPlace(place)}
              className="search-result-item"
            >
              <div className="search-result-title">{place.name}</div>
              <div className="search-result-description">{place.description.substring(0, 100)}...</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchBox;