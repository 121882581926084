export const locationsByCity = {
  "Tokyo": [
    {
      id: "tokyo-1",
      name: "Tokyo Tower",
      coordinates: [35.6586, 139.7454],
      description: "Famous landmark tower in Tokyo",
      estimatedTime: "30 min",
      audioGuide: "/audio/tokyo-tower.mp3",
      image: "/images/tokyo-tower.jpg",
      audioDescription: "Tokyo Tower, standing at 333 meters, is one of the most iconic landmarks of the city. Built in 1958, it serves as both a communications tower and a symbol of post-war rebirth in Japan. The tower was inspired by the Eiffel Tower but painted in white and orange to comply with air safety regulations. From its observation decks, you can enjoy spectacular views of Tokyo skyline."
    },
    {
      id: "tokyo-2",
      name: "Senso-ji Temple",
      coordinates: [35.7148, 139.7967],
      description: "Ancient Buddhist temple in Asakusa",
      estimatedTime: "45 min",
      audioGuide: "/audio/sensoji.mp3",
      image: "/images/sensoji.jpg",
      audioDescription: "Senso-ji is the oldest Buddhist temple in Tokyo, founded in 645 AD. The temple is dedicated to Kannon, the goddess of mercy. As you approach, you will pass through the famous Kaminarimon Gate with its massive red lantern. The temple complex includes a five-story pagoda and the bustling Nakamise shopping street."
    },
    {
      id: "tokyo-3",
      name: "Shibuya Crossing",
      coordinates: [35.6591, 139.7003],
      description: "World's busiest pedestrian crossing",
      estimatedTime: "20 min",
      audioGuide: "/audio/shibuya.mp3",
      image: "/images/shibuya.jpg",
      audioDescription: "Shibuya Crossing is often called the world's busiest pedestrian crossing. During peak times, over 2,500 people cross at once, creating a spectacular sight known as the Shibuya Scramble. The crossing is surrounded by giant video screens and neon signs, epitomizing modern Tokyo energy. The statue of Hachiko, the faithful dog, is located nearby."
    },
    {
      id: "tokyo-4",
      name: "Shinjuku Gyoen",
      coordinates: [35.6852, 139.7100],
      description: "Beautiful park with gardens",
      estimatedTime: "40 min",
      audioGuide: "/audio/shinjuku-gyoen.mp3",
      image: "/images/shinjuku-gyoen.jpg",
      audioDescription: "Shinjuku Gyoen is one of the largest and most beautiful parks in Tokyo. The park features three distinct styles: Japanese traditional, English landscape, and French formal gardens. It is especially popular during cherry blossom season in spring and autumn when the leaves change color. The park was originally a private garden of the Imperial Family."
    },
    {
      id: "tokyo-5",
      name: "Tokyo Skytree",
      coordinates: [35.7100, 139.8107],
      description: "Tallest structure in Japan and new symbol of Tokyo",
      estimatedTime: "60 min",
      audioGuide: "/audio/skytree.mp3",
      image: "/images/skytree.jpg",
      audioDescription: "Tokyo Skytree is the world's tallest tower at 634 meters. This broadcasting and observation tower offers spectacular views from its two observation decks at 350m and 450m. The tower is especially beautiful at night when it is illuminated with different colors. The base features a large shopping complex and aquarium."
    },
    {
      id: "tokyo-6",
      name: "Meiji Shrine",
      coordinates: [35.6763, 139.6993],
      description: "Sacred shrine dedicated to Emperor Meiji",
      estimatedTime: "45 min",
      audioGuide: "/audio/meiji.mp3",
      image: "/images/meiji.jpg",
      audioDescription: "Meiji Shrine, dedicated to Emperor Meiji and Empress Shoken, is set in a peaceful forest of 120,000 trees. The shrine complex features massive torii gates, beautiful gardens, and traditional architecture. Its peaceful atmosphere provides a stark contrast to the busy Harajuku area nearby."
    },
    {
      id: "tokyo-7",
      name: "Akihabara",
      coordinates: [35.6980, 139.7730],
      description: "Electronics and anime culture district",
      estimatedTime: "90 min",
      audioGuide: "/audio/akihabara.mp3",
      image: "/images/akihabara.jpg",
      audioDescription: "Akihabara, also known as Electric Town, is the center of Japan's otaku culture. This district is famous for its numerous electronics shops, anime and manga stores, maid cafes, and gaming centers. The streets are lined with colorful billboards and filled with the latest technology and pop culture merchandise."
    },
    {
      id: "tokyo-8",
      name: "Imperial Palace",
      coordinates: [35.6852, 139.7528],
      description: "Primary residence of the Emperor of Japan",
      estimatedTime: "40 min",
      audioGuide: "/audio/imperial.mp3",
      image: "/images/imperial.jpg",
      audioDescription: "The Imperial Palace, home to Japan's Imperial Family, is a large park-like area that still contains the original moat and massive stone walls. While the palace buildings are not open to the public, visitors can enjoy the East Gardens and the famous Nijubashi Bridge. The surrounding grounds are especially beautiful during cherry blossom season."
    },
    {
      id: "tokyo-9",
      name: "Tsukiji Outer Market",
      coordinates: [35.6654, 139.7707],
      description: "Famous market known for fresh seafood",
      estimatedTime: "60 min",
      audioGuide: "/audio/tsukiji.mp3",
      image: "/images/tsukiji.jpg",
      audioDescription: "While the famous tuna auctions have moved to Toyosu, the Tsukiji Outer Market remains a fascinating destination. Hundreds of small shops and restaurants offer the freshest seafood, traditional Japanese knives, and kitchenware. The market is best visited early morning when you can sample fresh sushi for breakfast."
    },
    {
      id: "tokyo-10",
      name: "Odaiba",
      coordinates: [35.6247, 139.7767],
      description: "Futuristic artificial island entertainment district",
      estimatedTime: "120 min",
      audioGuide: "/audio/odaiba.mp3",
      image: "/images/odaiba.jpg",
      audioDescription: "Odaiba is a popular shopping and entertainment district on a man-made island in Tokyo Bay. Notable attractions include the Fuji TV Building, Gundam statue, Joypolis indoor theme park, and Oedo Onsen hot spring theme park. The area offers great views of Rainbow Bridge and the Tokyo skyline."
    },
    {
      id: "tokyo-11",
      name: "Ueno Park",
      coordinates: [35.7147, 139.7713],
      description: "Large public park with multiple museums",
      estimatedTime: "90 min",
      audioGuide: "/audio/ueno.mp3",
      image: "/images/ueno.jpg",
      audioDescription: "Ueno Park is Tokyo's most popular park, especially during cherry blossom season. The park contains several major museums, including the Tokyo National Museum and the National Museum of Nature and Science. The beautiful grounds also include Ueno Zoo, Shinobazu Pond, and the historic Toshogu Shrine."
    },
    {
      id: "tokyo-12",
      name: "Harajuku",
      coordinates: [35.6716, 139.7031],
      description: "Youth culture and fashion district",
      estimatedTime: "60 min",
      audioGuide: "/audio/harajuku.mp3",
      image: "/images/harajuku.jpg",
      audioDescription: "Harajuku is the center of Japan's youth culture and street fashion. Takeshita Street is lined with colorful shops selling unique fashion items, crepe stands, and kawaii merchandise. The nearby Omotesando avenue offers a more upscale shopping experience with flagship stores of international luxury brands."
    }
  ],
  "New York": [
    {
      id: "ny-1",
      name: "Statue of Liberty",
      coordinates: [40.6892, -74.0445],
      description: "Iconic symbol of freedom",
      estimatedTime: "120 min",
      audioGuide: "/audio/liberty.mp3",
      image: "/images/liberty.jpg",
      audioDescription: "The Statue of Liberty..."
    }
  ],
  "Bishkek": [
    {
      id: "bishkek-1",
      name: "Ala-Too Square",
      coordinates: [42.8746, 74.6122],
      description: "Central square and popular gathering place",
      estimatedTime: "30 min",
      audioGuide: "/audio/ala-too-square.mp3",
      image: "/images/ala-too-square.jpg",
      audioDescription: "Ala-Too Square is the heart of Bishkek and a popular spot for locals and tourists. The square features a large open area, fountains, and statues, including the statue of Manas, the legendary Kyrgyz hero. It’s a great place to learn about the country’s history and see government buildings nearby."
    },
    {
      id: "bishkek-2",
      name: "Osh Bazaar",
      coordinates: [42.8706, 74.5791],
      description: "Bustling market with local goods and food",
      estimatedTime: "60 min",
      audioGuide: "/audio/osh-bazaar.mp3",
      image: "/images/osh-bazaar.jpg",
      audioDescription: "Osh Bazaar is one of Bishkek’s largest and most vibrant markets. It offers a unique glimpse into local life with its wide array of fresh produce, spices, traditional crafts, and souvenirs. Visitors can taste traditional Kyrgyz foods like samsa and lepyoshka bread."
    },
    {
      id: "bishkek-3",
      name: "Panfilov Park",
      coordinates: [42.8734, 74.6023],
      description: "Public park with amusement rides and a monument",
      estimatedTime: "45 min",
      audioGuide: "/audio/panfilov-park.mp3",
      image: "/images/panfilov-park.jpg",
      audioDescription: "Panfilov Park is a popular park in the center of Bishkek, known for its leafy paths, rides, and relaxing atmosphere. The park includes a memorial dedicated to Panfilov's 28 Guardsmen who fought in World War II. It’s a perfect spot to unwind and enjoy the local atmosphere."
    },
    {
      id: "bishkek-4",
      name: "Victory Square",
      coordinates: [42.8741, 74.6015],
      description: "War memorial square commemorating World War II",
      estimatedTime: "20 min",
      audioGuide: "/audio/victory-square.mp3",
      image: "/images/victory-square.jpg",
      audioDescription: "Victory Square is dedicated to the victory in World War II and serves as a place of remembrance. The square is dominated by a monument with three curved arches symbolizing a yurt, with an eternal flame burning in the center. The site is a solemn place for reflection."
    },
    {
      id: "bishkek-5",
      name: "Ala Archa National Park",
      coordinates: [42.4997, 74.4860],
      description: "Scenic national park with mountains and hiking trails",
      estimatedTime: "4 hours",
      audioGuide: "/audio/ala-archa.mp3",
      image: "/images/ala-archa.jpg",
      audioDescription: "Ala Archa National Park is a breathtaking natural reserve located just a short drive from Bishkek. It offers spectacular mountain scenery, hiking trails, and opportunities to see alpine wildlife. The park is a popular destination for hiking, picnics, and nature lovers."
    }
  ],
  "San Francisco": [
    {
      id: "sf-1",
      name: "Golden Gate Bridge",
      coordinates: [37.8199, -122.4783],
      description: "Iconic suspension bridge with scenic views",
      estimatedTime: "60 min",
      audioGuide: "/audio/golden-gate-bridge.mp3",
      image: "/images/golden-gate-bridge.jpg",
      audioDescription: "The Golden Gate Bridge is one of the most recognized landmarks in the world. Stretching 1.7 miles across San Francisco Bay, this striking orange suspension bridge offers breathtaking views of the city skyline and the Pacific Ocean."
    },
    {
      id: "sf-2",
      name: "Alcatraz Island",
      coordinates: [37.8267, -122.4230],
      description: "Historic island prison with tours",
      estimatedTime: "2 hours",
      audioGuide: "/audio/alcatraz.mp3",
      image: "/images/alcatraz.jpg",
      audioDescription: "Alcatraz Island, once a notorious federal prison, is now a fascinating historic site. Visitors can tour the prison cells and hear stories of famous inmates. The island also offers beautiful views of the San Francisco Bay and the city skyline."
    },
    {
      id: "sf-3",
      name: "Fisherman's Wharf",
      coordinates: [37.8080, -122.4177],
      description: "Tourist area known for seafood and sea lions",
      estimatedTime: "90 min",
      audioGuide: "/audio/fishermans-wharf.mp3",
      image: "/images/fishermans-wharf.jpg",
      audioDescription: "Fisherman's Wharf is a lively waterfront area known for its seafood restaurants, souvenir shops, and famous Pier 39, where you can see sea lions basking in the sun. It’s a popular spot for dining and enjoying views of the bay."
    },
    {
      id: "sf-4",
      name: "Union Square",
      coordinates: [37.7879, -122.4074],
      description: "Central shopping, dining, and hotel area",
      estimatedTime: "60 min",
      audioGuide: "/audio/union-square.mp3",
      image: "/images/union-square.jpg",
      audioDescription: "Union Square is San Francisco's premier shopping and cultural hub, surrounded by luxury stores, theaters, and art galleries. The area is known for its vibrant atmosphere and is a great spot to people-watch and explore the city's urban life."
    },
    {
      id: "sf-5",
      name: "Golden Gate Park",
      coordinates: [37.7694, -122.4862],
      description: "Large urban park with museums and gardens",
      estimatedTime: "2 hours",
      audioGuide: "/audio/golden-gate-park.mp3",
      image: "/images/golden-gate-park.jpg",
      audioDescription: "Golden Gate Park is an expansive green oasis in the city. It features gardens, lakes, and museums, including the de Young Museum and the California Academy of Sciences. The park is a favorite for outdoor activities, picnics, and leisurely walks."
    },
    {
      id: "sf-6",
      name: "Chinatown",
      coordinates: [37.7941, -122.4078],
      description: "Oldest and largest Chinatown in the U.S.",
      estimatedTime: "45 min",
      audioGuide: "/audio/chinatown.mp3",
      image: "/images/chinatown.jpg",
      audioDescription: "San Francisco's Chinatown is a vibrant neighborhood filled with colorful shops, temples, and traditional Chinese eateries. It’s a bustling area where you can explore the rich history and culture of the Chinese-American community."
    }
  ],
  "Los Angeles": [
    {
      id: "la-1",
      name: "Hollywood Walk of Fame",
      coordinates: [34.1016, -118.3269],
      description: "Famous sidewalk with celebrity stars",
      estimatedTime: "60 min",
      audioGuide: "/audio/hollywood-walk-of-fame.mp3",
      image: "/images/hollywood-walk-of-fame.jpg",
      audioDescription: "The Hollywood Walk of Fame stretches down Hollywood Boulevard and Vine Street, featuring over 2,600 stars honoring celebrities in film, television, music, and theater. It’s a must-see spot for fans of entertainment."
    },
    {
      id: "la-2",
      name: "Griffith Observatory",
      coordinates: [34.1184, -118.3004],
      description: "Observatory with city and star views",
      estimatedTime: "90 min",
      audioGuide: "/audio/griffith-observatory.mp3",
      image: "/images/griffith-observatory.jpg",
      audioDescription: "Griffith Observatory offers stunning views of Los Angeles and the iconic Hollywood Sign. The observatory features telescopes, exhibits on astronomy, and a planetarium. It’s especially popular at sunset when the city lights up below."
    },
    {
      id: "la-3",
      name: "Santa Monica Pier",
      coordinates: [34.0092, -118.4974],
      description: "Historic pier with amusement park",
      estimatedTime: "60 min",
      audioGuide: "/audio/santa-monica-pier.mp3",
      image: "/images/santa-monica-pier.jpg",
      audioDescription: "The Santa Monica Pier is a classic California landmark with an amusement park, an aquarium, and a variety of food stands. The pier offers beautiful ocean views and is a great place for a sunset walk."
    },
    {
      id: "la-4",
      name: "Los Angeles County Museum of Art (LACMA)",
      coordinates: [34.0638, -118.3589],
      description: "Largest art museum in the western U.S.",
      estimatedTime: "2 hours",
      audioGuide: "/audio/lacma.mp3",
      image: "/images/lacma.jpg",
      audioDescription: "LACMA features an extensive collection of artworks from around the world, including the iconic 'Urban Light' installation at its entrance. The museum hosts various exhibitions, making it a cultural hub in Los Angeles."
    },
    {
      id: "la-5",
      name: "The Getty Center",
      coordinates: [34.0780, -118.4741],
      description: "Museum with art, architecture, and gardens",
      estimatedTime: "2 hours",
      audioGuide: "/audio/getty-center.mp3",
      image: "/images/getty-center.jpg",
      audioDescription: "The Getty Center is known for its impressive art collection, stunning architecture, and meticulously landscaped gardens. Situated on a hill, it provides panoramic views of Los Angeles and the Pacific Ocean."
    },
    {
      id: "la-6",
      name: "Rodeo Drive",
      coordinates: [34.0672, -118.4010],
      description: "Luxury shopping district in Beverly Hills",
      estimatedTime: "45 min",
      audioGuide: "/audio/rodeo-drive.mp3",
      image: "/images/rodeo-drive.jpg",
      audioDescription: "Rodeo Drive is a world-famous shopping street lined with luxury boutiques and designer stores. It’s a prime destination for high-end shopping and celebrity sightings, known for its glamorous atmosphere."
    }
  ],

  // Other cities
};

  
  export const getNearestCity = (userCoords) => {
    const cities = Object.keys(locationsByCity);
    let nearestCity = null;
    let shortestDistance = Infinity;
  
    cities.forEach(city => {
      const firstLocation = locationsByCity[city][0];
      const distance = calculateDistance(
        userCoords,
        firstLocation.coordinates
      );
      
      if (distance < shortestDistance) {
        shortestDistance = distance;
        nearestCity = city;
      }
    });
  
    return nearestCity;
  };
  
  export const calculateDistance = (coord1, coord2) => {
    const R = 6371;
    const dLat = (coord2[0] - coord1[0]) * Math.PI / 180;
    const dLon = (coord2[1] - coord1[1]) * Math.PI / 180;
    const a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(coord1[0] * Math.PI / 180) * Math.cos(coord2[0] * Math.PI / 180) * 
      Math.sin(dLon/2) * Math.sin(dLon/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    return R * c;
  };