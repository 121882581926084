export const countriesData = {
    "Japan": ["Tokyo", "Osaka", "Kyoto"],
    "USA": ["New York", "Los Angeles", "San Francisco"],
    "France": ["Paris", "Lyon", "Marseille"],
    "Kyrgyzstan": ["Bishkek", "Issyk-Kul"],
    // Другие страны и города
  };
  
  export const getCountryByCity = (cityName) => {
    return Object.keys(countriesData).find(country => 
      countriesData[country].includes(cityName)
    );
  };