// frontend/src/components/LocationCard.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './LocationCard.css';

const LocationCard = ({ location, isFavorite, isGenerated, onFavoriteToggle }) => {
  const navigate = useNavigate();

  return (
    <div className="location-card">
      <img 
        src={location.image} 
        alt={location.name} 
        className="location-card-image"
      />
      <div className="location-card-content">
        <h3 className="location-card-title">{location.name}</h3>
        <p className="location-card-description">{location.description}</p>
        
        <div className="location-card-actions">
          {onFavoriteToggle && (
            <button 
              className={`favorite-btn ${isFavorite ? 'active' : ''}`}
              onClick={onFavoriteToggle}
            >
              <i className={`fas fa-heart ${isFavorite ? 'filled' : ''}`}></i>
              {isFavorite ? 'Remove from Favorites' : 'Add to Favorites'}
            </button>
          )}
          
          <button 
            className="view-btn"
            onClick={() => navigate(`/map?highlight=${location._id}`)}
          >
            <i className="fas fa-map-marker-alt"></i>
            View on Map
          </button>
        </div>
      </div>
    </div>
  );
};

export default LocationCard;