// File: frontend/src/components/PlaceCard.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './PlaceCard.css';

const PlaceCard = ({ place }) => {
  const navigate = useNavigate();

  const handleViewMore = () => {
    // Перенаправляем на карту с параметрами вместо /places/${place.id}
    navigate(`/map?highlight=${place.id}`);
  };

  return (
    <div className="place-card">
      <img src={place.image} alt={place.name} className="place-image" />
      <h2 className="place-name">{place.name}</h2>
      <p className="place-description">{place.description}</p>
      {place.audioClip && (
        <audio controls>
          <source src={place.audioClip} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      )}
      <button className="view-more" onClick={handleViewMore}>View More</button>
    </div>
  );
};

export default PlaceCard;