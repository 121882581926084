// frontend/src/components/HeaderProfile.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import './HeaderProfile.css';

const HeaderProfile = () => {
  const { user, login } = useAuth();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="header-profile">
      {!user ? (
        <button 
          className="profile-sign-in-btn"
          onClick={() => navigate('/profile')}
        >
          <i className="fas fa-user"></i>
          <span>Sign In</span>
        </button>
      ) : (
        <div className="profile-menu">
          <button 
            className="profile-avatar-btn"
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            <img 
              src={user.picture} 
              alt={user.name}
              className="profile-avatar-img"
            />
            <div className="profile-info">
              <span className="profile-name">{user.name}</span>
            </div>
          </button>

          {isDropdownOpen && (
            <div className="profile-dropdown">
              <div className="dropdown-header">
                <img 
                  src={user.picture} 
                  alt={user.name}
                  className="dropdown-avatar"
                />
                <div className="dropdown-user-info">
                  <span className="dropdown-name">{user.name}</span>
                  <span className="dropdown-email">{user.email}</span>
                </div>
              </div>
              
              <div className="dropdown-content">
                <button 
                  className="dropdown-item"
                  onClick={() => {
                    navigate('/profile');
                    setIsDropdownOpen(false);
                  }}
                >
                  <i className="fas fa-user-circle"></i>
                  My Profile
                </button>
                
                <button 
                  className="dropdown-item"
                  onClick={() => {
                    navigate('/profile?tab=favorites');
                    setIsDropdownOpen(false);
                  }}
                >
                  <i className="fas fa-heart"></i>
                  Favorites
                </button>
                
                <button 
                  className="dropdown-item"
                  onClick={() => {
                    navigate('/profile?tab=generated');
                    setIsDropdownOpen(false);
                  }}
                >
                  <i className="fas fa-map-marked-alt"></i>
                  My Locations
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default HeaderProfile;