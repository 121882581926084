// File: frontend/src/pages/Home.js
import React from 'react';
import { Link } from 'react-router-dom';
import PlaceCard from '../components/PlaceCard';
import SearchBox from '../components/SearchBox';
import HeaderProfile from '../components/HeaderProfile';  // Добавляем импорт
import { countriesData } from '../data/countries';
import { locationsByCity } from '../data/locations';
import './Home.css';

const Home = () => {
  return (
    <div className="home">
      <HeaderProfile />  {/* Добавляем компонент профиля */}
      <h1 className="title">Explore Famous Places Around the World</h1>
      <Link to="/map" className="map-link">Go to the Map View</Link>
      <div style={{ 
        maxWidth: '600px', 
        margin: '20px auto',
        padding: '0 20px'
      }}>
        <SearchBox />
      </div>
      {Object.keys(countriesData).map(country => (
        <div key={country} className="country-section">
          <h2 className="country-title">{country}</h2>
          {countriesData[country].map(cityName => {
            const cityLocations = locationsByCity[cityName] || [];
            return (
              <div key={cityName} className="area-section">
                <h3 className="area-title">{cityName}</h3>
                <div className="place-grid">
                  {cityLocations.map((place, index) => (
                    <PlaceCard key={place.id} place={place} />
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default Home;